import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { usePopperTooltip } from 'react-popper-tooltip'
import clsx from 'clsx'
import { SizeVariant } from 'enums'

import { Button } from 'components/buttons'

import { useLanguageSettings } from 'context/language-manager'

import { routes } from 'data/routes'
import { ILocale } from 'types/app'

import FlagIcon from './components/FlagIcon'
import { IMenuLanguageProps } from './types'

const MenuLanguage = ({ isMobile, onClick }: IMenuLanguageProps) => {
  const router = useRouter()
  const currentLanguage = router.locale ?? router.defaultLocale
  const alternateLanguages = router.locales ?? []
  const { switchLanguage } = useLanguageSettings()
  const { setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    placement: 'bottom',
    trigger: 'click',
    delayHide: 200
  })

  const onLangChange = useCallback(
    (lang: ILocale) => () => {
      switchLanguage(lang)
      onClick?.()

      const url = routes[router.pathname]?.[lang]
      const hasQuery = routes[router.pathname]?.query
      const query = Object.values(router.query)

      if (url && currentLanguage !== lang) {
        router.push(`${url}${hasQuery ? query[0] : ''}`, undefined, {
          shallow: true
        })
      }
    },
    [currentLanguage, onClick, router, switchLanguage]
  )

  return (
    <div ref={setTriggerRef} className="relative h-full lg:h-auto">
      <div className="lg flex h-full w-full cursor-pointer items-center rounded px-4 py-2 font-bold uppercase transition-all hover:bg-slate-100 lg:h-[40px] lg:w-auto lg:px-2">
        <span className="mr-2 block">{currentLanguage}</span>
        <FlagIcon
          lang={currentLanguage}
          style={
            isMobile
              ? {
                  width: '2rem',
                  height: '2rem',
                  lineHeight: '2rem'
                }
              : undefined
          }
        />
      </div>
      <div
        ref={setTooltipRef}
        className={clsx(
          'fade-in absolute right-0  z-50 rounded-md bg-slate-50 shadow-md',
          !visible ? 'hidden' : 'block',
          isMobile ? 'top-[100%]' : 'top-[125%]'
        )}
      >
        {alternateLanguages?.map((lang) => {
          return (
            <Button
              key={lang}
              size={SizeVariant.Small}
              className="my-1 flex w-full items-center border-none px-4 py-2 font-bold uppercase transition-all hover:bg-slate-200 hover:text-black"
              onClick={onLangChange(lang)}
            >
              <span className="mr-2">{lang}</span>
              <FlagIcon lang={lang} />
            </Button>
          )
        })}
      </div>
    </div>
  )
}

export default MenuLanguage
